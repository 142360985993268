import(/* webpackMode: "eager", webpackExports: ["StaticErrorPage","DynamicErrorPage"] */ "/home/runner/_work/42next-app-client/42next-app-client/apps/auth/src/app/[locale]/[...not-found]/error-page.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthenticationGuard"] */ "/home/runner/_work/42next-app-client/42next-app-client/libs/core-systems/src/auth/components/authentication-guard.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["useFailableServerAction","useServerAction"] */ "/home/runner/_work/42next-app-client/42next-app-client/libs/core-systems/src/http/client/use-server-action.ts");
;
import(/* webpackMode: "eager", webpackExports: ["TranslationsProvider"] */ "/home/runner/_work/42next-app-client/42next-app-client/libs/core-systems/src/i18n/client/translations.provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["useClientLocale"] */ "/home/runner/_work/42next-app-client/42next-app-client/libs/core-systems/src/i18n/client/use-locale.hook.ts");
;
import(/* webpackMode: "eager", webpackExports: ["useClientTranslations"] */ "/home/runner/_work/42next-app-client/42next-app-client/libs/core-systems/src/i18n/client/use-translation.hook.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/_work/42next-app-client/42next-app-client/node_modules/.pnpm/next-intl@3.26.3_next@15.2.3_@babel+core@7.26.10_@opentelemetry+api@1.8.0_@playwright+test@1._kcfdar7ejhuq4frnlc337k3y7y/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/_work/42next-app-client/42next-app-client/node_modules/.pnpm/next-intl@3.26.3_next@15.2.3_@babel+core@7.26.10_@opentelemetry+api@1.8.0_@playwright+test@1._kcfdar7ejhuq4frnlc337k3y7y/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
